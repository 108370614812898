import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "yeast"
    }}>{`Yeast`}</h1>
    <h2 {...{
      "id": "four-elements"
    }}>{`Four Elements`}</h2>
    <h3 {...{
      "id": "oxygen"
    }}>{`Oxygen`}</h3>
    <h3 {...{
      "id": "temperature"
    }}>{`Temperature`}</h3>
    <p>{`Lager yeast tend to be more sensitive to high temperatures.`}</p>
    <h3 {...{
      "id": "sugar"
    }}>{`Sugar`}</h3>
    <h3 {...{
      "id": "ph"
    }}>{`pH`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      